// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-case-studies-mma-js": () => import("./../../../src/pages/case-studies/mma.js" /* webpackChunkName: "component---src-pages-case-studies-mma-js" */),
  "component---src-pages-case-studies-potwin-js": () => import("./../../../src/pages/case-studies/potwin.js" /* webpackChunkName: "component---src-pages-case-studies-potwin-js" */),
  "component---src-pages-case-studies-ranker-js": () => import("./../../../src/pages/case-studies/ranker.js" /* webpackChunkName: "component---src-pages-case-studies-ranker-js" */),
  "component---src-pages-case-studies-rexford-js": () => import("./../../../src/pages/case-studies/rexford.js" /* webpackChunkName: "component---src-pages-case-studies-rexford-js" */),
  "component---src-pages-case-studies-timestamp-js": () => import("./../../../src/pages/case-studies/timestamp.js" /* webpackChunkName: "component---src-pages-case-studies-timestamp-js" */),
  "component---src-pages-case-studies-vsainte-js": () => import("./../../../src/pages/case-studies/vsainte.js" /* webpackChunkName: "component---src-pages-case-studies-vsainte-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

